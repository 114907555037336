import React, {useEffect} from "react";
import { graphql} from "gatsby";

import Head from "../head";
import Layout from './layout';

import '../../styles/index.scss'
import * as blogPostStyles from '../component-styles/blogPost.module.scss';


//import the Prism package
import Prism from "prismjs"

export const query = graphql`
    query(
    $slug: String!
    ) {
      markdownRemark (
        fields: {
          slug: {
            eq: $slug
          }
        }
      ) {
        frontmatter {
          title
          date
          tags
          description
        }

        html
      }
    }
  `
const BlogPost = (props) => {

  useEffect(() =>{ /// code highilighting in post
    Prism.highlightAll()
  })

  const blogData = props.data.markdownRemark
  const metadata = blogData.frontmatter

  const title = metadata.title
  const description = metadata.description
  const url = props.url
  return(
    <Layout
      title={title} 
      description={description}
      url={url}
      page="Post"
    >
      <Head title={title} />
      <div className={blogPostStyles.header}>
        <h1 className={blogPostStyles.title}>{title}</h1>
        <ol className={blogPostStyles.tagList}>
          {metadata.tags.map((tag, index) => {
            return <li key={index} className={blogPostStyles.tag}><i>{tag}</i></li>
          })}
        </ol>
      </div>
      <div className={blogPostStyles.content} dangerouslySetInnerHTML = {{ __html: blogData.html}}>
      </div>
    </Layout>
  )
}

export default BlogPost 